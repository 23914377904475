import React from 'react'
import { graphql, useStaticQuery } from 'gatsby';
import { getImage } from 'gatsby-plugin-image';
import { BgImage } from 'gbimage-bridge';

const BridgeTest = () => {
  const { placeholderImage } = useStaticQuery(
          graphql`
      query {
        placeholderImage: file(relativePath: { eq: "bgImage-resitrans.jpeg" }) {
          childImageSharp {
            gatsbyImageData(
              width: 1000
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
            )
          }
        }
      }
    `
  )
  const pluginImage = getImage(placeholderImage);

  return (
      <div className="jumbotron p-3" >
          <BgImage image={pluginImage} style={{ minWidth: 200, minHeight: 200 }}>
          <div>
      <h1 className="diplay-4 p-3">El futuro de las residencias empieza aquí</h1>
      <p className="lead p-3">Estamos en el camino de consturir algo innovador. Queremos entregar datos que aporten valor a los profecionales del sectro geroasistencial.</p>
      <hr className="my-4"></hr>
      <p>Aquí podras descargar los informes y acceder a los datos del proyecto</p>
      <p className="lead p-3">
    
      </p>
    </div>
          </BgImage>
          </div>

  )
}
export default BridgeTest